import '../index.scss';
import React from 'react';
import ViyoLogo from '../components/viyoLogo';

import Footer from "../components/footer";

class Security extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    document.body.classList.remove('landing-page');
    document.body.classList.add('inner-page');
    document.documentElement.style.background = '#394261';

    return (
      <div className='SecurityDetails'>
        <nav className="navbar navbar-expand-lg  theme-nav fixed-top darkHeader">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}><ViyoLogo/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Pricing</a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
                </li> */}

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/register" className='btn btn-sm btn-register'>Register</a></div>
                </li>

                  <li className='nav-item appButton'>
                    <div className='nav-button'>
                      <a href="https://app.viyo.io/login" className='btn btn-sm btn-register '>Login</a></div>
                  </li>
              </ul>
            </div>
          </div>
        </nav>
        <div>
          <section>
            <div className='section-title'>
              <h1>Viyo Security Explained</h1>
            </div>

            <div className='container'>
              <p>The two questions we get asked the most are:</p>
              <ul>
                <li>1. Is Viyo Secure?</li>
                <li>2. Could someone view my cameras?</li>
              </ul>
              <p>As security is at the forefront of how we manage your data, we will break down the major parts of Viyo to give you a greater perspective into how Viyo works, and why you can use Viyo with confidence.</p>

              <div className='quickLinks'>
                <span>Topic Links</span>

                <a href='#Https'>HTTPS</a>
                <a href='#PasswordEncryption'>Passwords</a>
                <a href='#CreditCardInfo'>Credit Cards</a>
                <a href='#Cameras'>Camera Data</a>
                <a href='#Motion'>Motion Tracking</a>
              </div>
            </div>
          </section>

          <section className='theme-bg feature' id='Https'>
            <div className='section-title'>
              <h2>HTTPS</h2>
              <img src={require('../assets/images/white-line.png')} alt='Title Divider' />
            </div>
            <div className='container'>
              <p>All connections to Viyo are required to use HTTPS over TLS. HTTPS helps provide you with extra privacy and security. It prevents malicious users from observing communication or behaviours between you and Viyo.</p>
            </div>
          </section>

          <section id='PasswordEncryption'>
            <div className='section-title'>
              <h2>Password Encryption</h2>
              <img src={require('../assets/images/title-line.png')} alt='Title Divider' />
            </div>

            <div className='container'>
              <p>Passwords are never stored in our databases in plain text. We take password security very seriously. Passwords are always highly encrypted via HMAC using SHA-256 hashing algorithms.</p>
            </div>
          </section>

          <section className='theme-bg feature' id='CreditCardInfo'>
            <div className='section-title'>
              <h2>Credit Card Information</h2>
              <img src={require('../assets/images/white-line.png')} alt='Title Divider' />
            </div>

            <div className='container'>
              <p><strong>We do not store any credit card information.</strong> We use Stripe billing to manage our subscription cycle, and credit card details are managed by Stripe. Stripe is PCI Level I encrypted. This is the most stringent level of certification available in the payments industry. They are frequently audited and are one of the leading online billing platforms. You can read their security details here: <a href="https://stripe.com/docs/security/stripe">https://stripe.com/docs/security/stripe</a></p>
            </div>
          </section>

          <section>
            <div className='section-title' id='Cameras'>
              <h2>Could Someone View My Cameras?</h2>
              <img src={require('../assets/images/title-line.png')} alt='Title Divider' />
            </div>

            <div className='container'>
              <p>When you set up a camera with Viyo, we use industry standard protocols and encryption. For video and audio data transfer, we use WebRTC technology built on top of a STUN / TURN service. This is to protect you and your camera data and to ensure that only you are viewing your cameras. In 85%-90% of cases when you observe one of your cameras, you will be connecting directly to your device and there is no "middleman". In these cases, all audio and video data is streamed from your camera device directly to your observing device. Here is an example diagram:</p>
              <img alt="STUN connection diagram" src={require('../assets/images/security-1.jpg')} className='securityDiagram STUN'/>

              <p>When your camera device is idle and is not being observed, no audio or video data transfer occurs. Your camera device waits for you to observe it, and then the data transfer begins between the devices.</p>

              <p>In the other 15%-20% of cases, it usually means that one of your devices is behind a NAT firewall. If this is the case, your devices will not be able to send audio and video data directly to each other. This is where our TURN service comes into play. The TURN service simply relays audio and video data from your camera device directly to your observing device. Audio and video data is encrypted between your two devices and is never stored. Video and audio data is only decrypted by your observing device when the WebRTC handshake has successfully completed and you are viewing your camera through the Viyo app. Here is an example diagram:</p>
              <img alt="TURN connection diagram" src={require('../assets/images/security-2.jpg')} className='securityDiagram TURN'/>

              <p>A malicious user could potentially gain access to your cameras by logging into Viyo using your email and password combination. We recommend frequently changing your password, and using a password that is secure and uses a combination of letters, numbers and special characters. We also recommend using a password and email combination unique to Viyo in case your password has been leaked through other services. Be careful not to share your password or account details with anyone else.</p>
            </div>
          </section>

          <section className='theme-bg feature' id='Motion'>
            <div className='section-title'>
              <h2>How Does Motion Tracking Work?</h2>
              <img src={require('../assets/images/white-line.png')} alt='Title Divider' />
            </div>

            <div className='container'>
              <p>Viyo uses a very unique algorithm to analyze motion tracking. All motion tracking happens directly on your device. Snapshots of your video feed are never sent, stored or analyzed by our servers. Many of the leading video monitoring products on the market today will analyze your video and audio data through a server to detect motion which is a significant breach of your privacy, and is not normally disclosed by these services.</p>
            </div>
          </section>

          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}


export default Security;