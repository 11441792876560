import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root"></div>
        <footer className="cpoy-right-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center subLinks">
                <ul>
                  {/* <li>
                    <a onClick={() => this.props.history.push('/contact')}>
                      Contact
                    </a>
                  </li> */}
                  {/* <li><a onClick={() => this.props.history.push('/privacy#top')}>Privacy</a></li> */}
                  {/* <li><a onClick={() => this.props.history.push('/faq')}>FAQ</a></li> */}
                  {/* Changed links due to netlify errors. Paskwa Mutunga June 2020 content changes */}
                  <li>
                    <a className="scroll-link" href="/contact">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a className="scroll-link" href="/privacy">
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a className="scroll-link" href="/FAQ">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a className="scroll-link" href="#compatibility">
                      Compatibility
                    </a>
                  </li>
                  {/* <li><a onClick={() => this.props.history.push('/security')}>Security</a></li> */}
                </ul>
              </div>
              <div className="col-md-12 text-center">
                <div className="social-footer">
                  <ul>
                    <li>
                      {/* rel="noopener noreferrer" should be added to links containing target="_blank" as a precaution against reverse tabnabbing. For more information, please refer to the following article:
https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/

AsciiDoc: http://example.com[Link^]
HTML: <a href="http://example.com" target="_blank">Link</a>
Fix: <a href="http://example.com" target="_blank" rel="noopener noreferrer">Link</a> */}
                      <a
                        href="https://facebook.com/www.viyo.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{
                          __html:
                            '<i class="fa fa-facebook" aria-hidden="true"></i>',
                        }}
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/viyo_io"
                        target="_blank"
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{
                          __html:
                            '<i class="fa fa-twitter" aria-hidden="true"></i>',
                        }}
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/viyo.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{
                          __html:
                            '<i class="fa fa-instagram" aria-hidden="true"></i>',
                        }}
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <p className="copyright">
                  Created by your friends at{' '}
                  <a
                    href="http://stackfive.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stack Five
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
