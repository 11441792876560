import DetectRTC from 'detectrtc';

const CompatibilityUtilities = {
  promisify: (timeout, value) => {
    return new Promise((resolve) => {
      setTimeout(() => { return resolve(value); }, timeout * 1000);
    });
  },
  initializeDetection: async (timeout) => {
    await DetectRTC.load();
    return CompatibilityUtilities.promisify(timeout, true);
  },
  checkHasVideoPermissions: (timeout) => {
    const hasPerm = DetectRTC.isWebsiteHasWebcamPermissions;
    return CompatibilityUtilities.promisify(timeout, hasPerm);
  },

  checkHasMicPermissions: (timeout) => {
    const hasPerm = DetectRTC.isWebsiteHasMicrophonePermissions;
    return CompatibilityUtilities.promisify(timeout, hasPerm);
  },

  checkHasWebSocketsSupport: async (timeout) => {
    return new Promise((resolve) => {
      return DetectRTC.checkWebSocketsSupport(function() {
        const hasPerm = !DetectRTC.isWebSocketsBlocked;
        return resolve(CompatibilityUtilities.promisify(timeout, hasPerm));
      });
    });
  },
  checkHasVideoDevices: (timeout) => {
    const hasDevices = DetectRTC.videoInputDevices;
    return CompatibilityUtilities.promisify(timeout, hasDevices);
  },
  checkHasAudioDevices: (timeout) => {
    const hasDevices = DetectRTC.audioInputDevices;
    return CompatibilityUtilities.promisify(timeout, hasDevices);
  },
  isWebRTCSupported: (timeout) => {
    const isAllowed = DetectRTC.isWebRTCSupported;
    return CompatibilityUtilities.promisify(timeout, isAllowed);
  }
}

export default CompatibilityUtilities;