import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import adapter from 'webrtc-adapter';
import NoMatch from './pages/404';
import HomeGeneral from './homeGeneral';
import Contact from './components/contact';
import PrivacyPolicy from './components/privacyPolicy';
import Compatibility from './pages/compatibility';
import Security from './pages/security';
// import HomePet from './homePet';
// import HomeSecurity from './homeSecurity';
// import HomeBaby from './homeBaby';
import PageNotFound from './pages/404';
import Faq from './pages/faq';
// import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Root extends React.Component {
  UNSAFE_componentWillMount() {
    const isAuthed = document.cookie.indexOf('UVIsAuthed=true') > -1;

    if (isAuthed && window.location.pathname === '/') {
      window.location = 'https://app.viyo.io'
    }
  }

  componentDidMount() {
    const selectedTheme = cookies.get('selectedTheme') || 'GeneralTheme';

  	document.body.classList.add(selectedTheme);
  }
  render() {
  	return(
  		<BrowserRouter basename={'/'} >
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeGeneral}/>
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
          <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={PrivacyPolicy}/>
          <Route exact path={`${process.env.PUBLIC_URL}/compatibility`} component={Compatibility}/>
          <Route exact path={`${process.env.PUBLIC_URL}/security`} component={Security}/>
          {/* <Route exact path={`${process.env.PUBLIC_URL}/baby-monitor`} component={HomeBaby}/> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/security`} component={HomeSecurity}/> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/pet-camera`} component={HomePet}/> */}
          <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq}/>
          <Route path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound}/>
          <Route component={NoMatch} />
        </Switch>
		</BrowserRouter>
  	);
  }
 }

ReactDOM.render(<Root />, document.getElementById('root'));

// registerServiceWorker();
