import React from 'react';
import WaveBG from './../assets/images/created-by-bg-wave.svg';
import PhoneScreenshot from './../assets/images/phone-screenshot.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const usecaseimages = [
  require('../assets/images/usecases/1.jpg'),
  require('../assets/images/usecases/2.jpg'),
  require('../assets/images/usecases/3.jpg'),
  require('../assets/images/usecases/4.jpg'),
];

// Dynamic Use case Easy to Update
let data = [
  {
    name: 'Work from home parents',
    description: 'Keep up with your children from the comfort of your desk.',
    prev: 'Anyone Who Needs An Extra Set of Eyes',
    next: 'Home Monitoring',
  },
  {
    name: 'Home Monitoring',
    description: 'Check in on your loved ones, wherever they may be. ',
    prev: 'Work from home parents',
    next: 'Anyone Who Needs An Extra Set of Eyes',
  },
  {
    name: 'Anyone Who Needs An Extra Set of Eyes',
    description: 'Stay in touch with your pets with ease ',
    prev: 'Home Monitoring',
    next: 'Work from home parents',
  },
];

class UseCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsecase: false,
      slideIndex: 0,
      updateCount: 0,
    };
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      pauseOnFocus: true,
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    return (
      <>
        {/* Use Case Section begins */}
        <section id="created-by" className="created-by theme-bg">
          <div className="wave-bg-container top">
            <img src={WaveBG} alt="wave background" className="wave-bg" />
          </div>

          {/* Use case Cards Start */}
          <div className="container">
            <div className="row usecasenav">
              <div className="col-md-6 text-center text-md-left">
                <h2 className="text-white useCaseh2">Use Cases</h2>
              </div>
              <div className="col-md-6 text-center text-md-right ">
                <h5
                  className="useCaseheading"
                  // onClick={() => {
                  //   this.slider.slickGoTo(0);
                  //   // console.log('clicked');
                  // }}
                  onClick={() => {
                    this.slider.slickGoTo(0);
                    console.log(this.state.slideIndex);
                  }}
                >
                  {' '}
                  <span
                    className={
                      this.state.slideIndex === 0
                        ? 'usecaseActive'
                        : 'inactiveUsecase'
                    }
                  >
                    {' '}
                    {data[0].name}
                  </span>
                  <span
                    className={this.state.slideIndex === 0 ? 'arrow-left' : ' '}
                  ></span>
                </h5>
                <h5
                  className="useCaseheading"
                  // onClick={() => {
                  //   this.slider.slickGoTo(1);
                  //   console.log('clicked');
                  // }}
                  onClick={() => {
                    this.slider.slickGoTo(1);
                    console.log(this.state.slideIndex);
                  }}
                >
                  {' '}
                  <span
                    className={
                      this.state.slideIndex === 1
                        ? 'usecaseActive'
                        : 'inactiveUsecase'
                    }
                  >
                    {' '}
                    {data[1].name}
                  </span>
                  <span
                    className={this.state.slideIndex === 1 ? 'arrow-left' : ' '}
                  ></span>
                </h5>
                <h5
                  className="useCaseheading"
                  onClick={() => {
                    this.slider.slickGoTo(2);
                    console.log(this.state.slideIndex);
                  }}
                >
                  {' '}
                  <span
                    className={
                      this.state.slideIndex === 2
                        ? 'usecaseActive'
                        : 'inactiveUsecase'
                    }
                  >
                    {' '}
                    {data[2].name}
                  </span>
                  <span
                    className={this.state.slideIndex === 2 ? 'arrow-left' : ' '}
                  ></span>
                </h5>
                {/* <Activateusecase title={data[0].name}></Activateusecase>
                <Activateusecase title={data[1].name}></Activateusecase>
                <Activateusecase title={data[2].name}></Activateusecase> */}
              </div>
            </div>
            {/* <h2> Use Cases</h2> */}
            <div style={{ textAlign: 'center' }}></div>
            <Slider ref={(slider) => (this.slider = slider)} {...settings}>
              {/* <Slider
              ref={(slider) => (this.slider = slider)} 
              // nextArrow={<Arrow type="next" />}
              // prevArrow={<Arrow type="prev" />}
              // dots={false}
              // fade={true}
              // infinite={true}
              // speed={1000}
              // slidesToShow={1}
              // slidesToScroll={1}
              // autoplay={true}
              // autoplaySpeed={5000}
              // pauseOnHover={true}
              // pauseOnFocus={true}
              // afterChange={() =>
              //   this.setState((state) => ({
              //     updateCount: state.updateCount + 1,
              //   }))
              // }
              // beforeChange={(current, next) =>
              //   this.setState({ slideIndex: next })
              // }
            >*/}
              <div>
                {' '}
                <div className="container useCaseWrapper">
                  <div className="row ">
                    <div className="col-md-6 vertical-center text-right">
                      <img
                        src={`${usecaseimages[0]}`}
                        alt="1"
                        className="useCaseImage"
                      />
                    </div>
                    <div className="col-md-6 text-left vertical-center">
                      <h2 className="text-white">{data[0].name}</h2>
                      <p className="text-white">{data[0].description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {' '}
                <div className="container useCaseWrapper">
                  <div className="row ">
                    <div className="col-md-6 vertical-center text-right">
                      <img
                        src={`${usecaseimages[1]}`}
                        alt="1"
                        className="useCaseImage"
                      />
                    </div>
                    <div className="col-md-6 text-left vertical-center">
                      <h2 className="text-white">{data[1].name}</h2>
                      <p className="text-white">{data[1].description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="container useCaseWrapper">
                  <div className="row ">
                    <div className="col-md-6 vertical-center text-right">
                      <img
                        src={`${usecaseimages[2]}`}
                        alt="1"
                        className="useCaseImage"
                      />
                    </div>
                    <div className="col-md-6 text-left vertical-center">
                      <h2 className="text-white">{data[2].name}</h2>
                      <p className="text-white">{data[2].description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          {/* Use Case Cards end */}

          <div className="wave-bg-container bottom">
            <img src={WaveBG} alt="wave background" className="wave-bg" />
          </div>
        </section>
        {/* Use Case Section ends */}

        <section id="privacy-security-core" className="privacy-security-core">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xs-12 text-md-left text-center">
                {/* <h2>
                  A browser-based home monitoring solution with privacy and
                  security at its very core.
                </h2> */}
                {/* <p>
                  In today's world, the video monitoring industry is dominated
                  by large companies that access and sell your data. They make
                  you choose between your security and your privacy. You
                  shouldn’t have to make that compromise.
                  <br />
                  <br />
                  As parents and pet-owners ourselves, we take privacy
                  seriously—especially when it comes to the most personal and
                  sensitive area of your life. That's why we built Viyo as a
                  browser-based app so none of your data has to be stored on
                  servers. <a href="/security">Click here</a> to learn more
                  about how Viyo works and how it protects your data.
                </p> */}
                <h2>Browser-Based Monitoring</h2>
                <p className="getStarted-text">
                  In today's world, the video monitoring industry is dominated
                  by large companies that access and sell your data. They make
                  you choose between keeping an eye on family and your privacy.
                  We didn't want to compromise, and neither should you.
                  <br />
                  <br />
                  <a href="/security">Click here</a> to learn more about how
                  Viyo works and how it protects your data.
                </p>
              </div>
              <div className="col-md-4 col-xs-12 text-md-left text-center">
                <img
                  src={PhoneScreenshot}
                  alt="Privacy and security are at the core of Viyo"
                  className="phone-screenshot"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default UseCases;
