import '../index.scss';
import React from 'react';
import Footer from "../components/footer";
import Fuse from 'fuse.js';
import ViyoLogo from '../components/viyoLogo';


const FAQData = require('../data/faq.js');

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search           : '',
      selectedCategory : null,
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  renderCategories = () => {
    if (!FAQData) return;

    const Categories = FAQData.categories.map((category, idx) => {
      const isSelected = category.name === this.state.selectedCategory;

      return <div className={`${isSelected ? 'selected' : ''} col-md-4 col-sm-12`} key={idx} onClick={() => this.setState({ selectedCategory: category.name })}>
        <div className='card category shadow bg-white rounded'>
          <div className='card-body align-items-center d-flex justify-content-center'>
            <i className={`fa ${category.icon}`} />
            <h2>{category.name}</h2>
          </div>
        </div>
      </div>
    });

    return <div className='row'>
      {Categories}
    </div>
  };

  renderSearchResults = () => {
    const { search, selectedCategory } = this.state;
    if (!FAQData) return;
    const FuseSearch = new Fuse(FAQData.questions, {
      shouldSort         : true,
      threshold          : 0.6,
      location           : 0,
      distance           : 100,
      minMatchCharLength : 1,
      keys               : selectedCategory ? ['categories'] : [ 'title', 'body' ]
    });

    const filteredResults = FuseSearch.search(selectedCategory || search);

    console.log('~~ filteredResults: ', filteredResults);

    if (filteredResults.length === 0 || (!search && !selectedCategory)) {
      return <div className='col-md-12 text-left'>
        <button className='btn btn-theme theme-color' onClick={() => this.setState({ search: '', selectedCategory: null })}><i className='fa fa-arrow-left'/> Back To Categories</button><br/>
        <p>Your search returned no results.</p>
      </div>
    }

    const SearchResults = filteredResults.map((question, idx) => {
      return <div className='col-sm-12 faq-toggle' key={idx}>
        <div className='card question shadow bg-white rounded'>
          <div className="card-header" id={`heading${idx}`}>
            <h5 className="mb-0">
              <p className="" data-toggle="collapse" data-target={`#collapse${idx}`} aria-expanded="true" aria-controls={`collapse${idx}`}>
                {question.title}
              </p>
            </h5>
          </div>

          <div id={`collapse${idx}`} className='collapse' aria-labelledby={`heading${idx}`} data-parent="#PopularQuestions">
            <div className='card-body align-items-center d-flex justify-content-center'>
              <div dangerouslySetInnerHTML={{ __html: question.body }}></div>
            </div>
          </div>
        </div>
      </div>
    });

    return <div className='row'>
      <div className='col-md-12 text-left'><button className='btn btn-theme theme-color' onClick={() => this.setState({ search: '', selectedCategory: null })}><i className='fa fa-arrow-left'/> Back To Categories</button></div>
      <div className='col-md-12 text-left'>
        <h3><strong>Search</strong> results {selectedCategory && `(${selectedCategory})`}</h3>
      </div>

      {SearchResults}
    </div>
  };

  renderPopularQuestions = () => {
    if (!FAQData) return;
    const popularQuestions = FAQData.questions.filter((question) => question.popular);

    const questions = popularQuestions.map((question, idx) => {
      return <div className='col-sm-12 faq-toggle' key={idx}>
        <div className='card question shadow bg-white rounded'>
          <div className="card-header" id={`heading${idx}`}>
            <h5 className="mb-0">
              <p className="" data-toggle="collapse" data-target={`#collapse${idx}`} aria-expanded="true" aria-controls={`collapse${idx}`}>
                {question.title}
              </p>
            </h5>
          </div>

          <div id={`collapse${idx}`} className='collapse' aria-labelledby={`heading${idx}`} data-parent="#PopularQuestions">
            <div className='card-body align-items-center d-flex justify-content-center'>
              <div dangerouslySetInnerHTML={{ __html: question.body }}></div>
            </div>
          </div>
        </div>
      </div>
    });

    return <div id='PopularQuestions' className='row align-items-center d-flex justify-content-center'>
      <h3><strong>Popular</strong> Questions</h3>

      {questions}
    </div>;
  }

  render() {
    const { search, selectedCategory } = this.state;

    document.body.classList.remove('landing-page');
    document.body.classList.add('inner-page');
    return (
      <div className='frequentlyAskedQuestions'>
        {/* Navbar Component*/}
        <nav className="navbar navbar-expand-lg  theme-nav fixed-top darkHeader">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}><ViyoLogo/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Pricing</a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
                </li> */}

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/register" className='btn btn-sm btn-register'>Register</a></div>
                </li>

                  <li className='nav-item appButton'>
                    <div className='nav-button'>
                      <a href="https://app.viyo.io/login" className='btn btn-sm btn-register '>Login</a></div>
                  </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <div className='navspacer'></div> */}
        {/*blog Section start*/}
        <div className="page-margin">
        <h1>Frequently Asked Questions</h1>
          {/*sign in*/}
          <section className="faq blog-page">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <form className="auth-form">
                    <div className="form-group">
                      <i className="fa fa-search"></i>
                      <input required="" value={this.state.search} name="search" type="text" className="form-control" onChange={(e) => this.setState({ search: e.target.value })} id="search" placeholder="Search..." />
                    </div>
                  </form>
                </div>

                <div className='col-md-12 text-center'>
                  <h3>Or <strong>Browse</strong> Categories</h3>
                </div>

                <div className={`${(search || selectedCategory) ? 'open' : 'closed'} col-md-12 text-center searchResults text-center`}>
                  {this.renderSearchResults()}
                </div>

                <div className={`${(search || selectedCategory) ? 'closed' : 'open'} col-md-12 text-center categories text-center`}>
                  {this.renderCategories()}
                </div>

                <div className={`${(search || selectedCategory) ? 'closed' : 'open'} col-md-12 text-center popularQuestions text-center`}>
                  {this.renderPopularQuestions()}
                </div>
              </div>
            </div>
            {/* <div className='bubbles'>
              <div className="animation-circle absolute">
                <i></i>
                <i></i>
                <i></i>
              </div>
              <div className="animation-circle-inverse">
                <i></i>
                <i></i>
                <i></i>
              </div>
            </div> */}
          </section>

          <Footer history={this.props.history}/>
        </div>
      </div>
    );
  }
}


export default Faq;