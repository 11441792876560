import React, { useState } from 'react';
// import { length } from 'file-loader';

function Accordion(props) {
  const [panelOpenState, setPanelState] = useState([]);

  const togglePanel = (index) => {
    let openState = new Array(props.data.length).fill(false);
    if (panelOpenState[index] !== true) openState[index] = true;
    setPanelState(openState);
  };

  return (
    <React.Fragment>
      {props.data.map((collapsible, index) => {
        return (
          <div className="" key={index}>
            <div className="featureHeader" onClick={() => togglePanel(index)}>
              <span
                className={
                  panelOpenState[index] === true ? 'arrow-down' : 'arrow-right'
                }
              >
                {' '}
              </span>
              <span>{collapsible.heading}</span>
            </div>
            {panelOpenState[index] === true && (
              // <div className="mobilecontent">
              <div className="featureContent multiPhone">
                {' '}
                <i className={collapsible.icon1} aria-hidden="true"></i>
                <i className={collapsible.icon2} aria-hidden="true"></i>
                <i className={collapsible.icon3} aria-hidden="true"></i>
                <i className={collapsible.icon4} aria-hidden="true"></i>
                <p>{collapsible.content}</p>
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default Accordion;

// export default Collapsible;

// Tips: https://medium.com/@subalerts/implememting-a-simple-collapsible-component-in-react-js-67c796e64652

// https://codesandbox.io/s/accordion-o0j18?file=/src/Accordion.js
