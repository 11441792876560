import '../index.scss';
import React from 'react';
import ViyoLogo from '../components/viyoLogo';
import Footer from "../components/footer";
import CompatibilityChecker from '../components/compatibilityChecker';

class Compatibility extends React.Component {
  render() {
    document.body.classList.remove('landing-page');
    document.body.classList.add('inner-page');
    document.documentElement.style.background = '#394261';

    return (
      <div className='compatibility'>
        {/* Navbar Component*/}
        <nav className="navbar navbar-expand-lg  theme-nav fixed-top darkHeader">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}><ViyoLogo/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Pricing</a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
                </li> */}

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/register" className='btn btn-sm btn-register'>Register</a></div>
                </li>

                  <li className='nav-item appButton'>
                    <div className='nav-button'>
                      <a href="https://app.viyo.io/login" className='btn btn-sm btn-register '>Login</a></div>
                  </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className='navspacer'></div>
        {/*blog Section start*/}
        <div className="page-margin">
          <CompatibilityChecker />
          {/*sign in*/}

          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}


export default Compatibility;