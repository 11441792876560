const categories = [
  {
    name : 'Device Assitance',
    icon : 'fa-mobile-phone',
  },
  {
    name: 'Account Issues',
    icon: 'fa-user'
  }, {
    name: 'Payment and Billing',
    icon: 'fa-money'
  }
]

// {
//   categories: [],
//   title: '',
//   popular: true,
//   body: ``
// }

const questions = [
  {
    categories: ['Device Assistance', 'Account Issues'],
    title: 'Are my camera feeds secure?',
    popular: true,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>Yes!</span>
            <p>Privacy is our number one focus. All video and audio streamed through Viyo is highly encrypted, as well as completely private and anonymous. We do not store, analyze or process any identifiable data related to your video or audio feeds. Unlike our competitors, motion tracking frame recognition happens directly through your device using a proprietary algorithm.</p>
          </div>
    `
  },
  {
    categories: ['Device Assistance', 'Account Issues'],
    title: 'Viyo does not load on my device',
    popular: false,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>Viyo may not be compatible with your current operating system or browser. Please follow the trouble shooting steps below.</span>
            <ol>
              <li>Ensure your device is updated to the latest operating system version. Please check in your device settings for any software updates.</li>
              <li>If your device is updated, please ensure your device browsers are also updated to the latest version. If you are using a third-party web browser on your device, please ensure there are no pending updates in the app store.</li>
              <li>If the above steps do not work, please download Google Chrome or Firefox from the app store on your device.</li>
              <li>You can also test your device compatibility by using our <a href="/compatibility"><button class='btn btn-sm btn-register secondary margin-top'>Device Compatibility Checker</button></a></li>
            </ol>
          </div>
    `
  },
  {
    categories: ['Device Assitance', 'Account Issues'],
    title: 'I am seeing a warning that my device has no cameras',
    popular: true,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>There are a few common cases where you may run into this issue. Please follow the steps below to troubleshoot. If you are still having issues, please reach out to customer service for assistance.</span>
            <ol>
              <li>Your camera may be in use by another application on your device. Please make sure all other camera applications are closed. Some applications on your device may use your camera in the background, so a device restart may be necessary.</li>
              <li>Your device camera may not be plugged in to your device properly. If you are using an external USB camera or wireless camera, please ensure your camera drivers are up to date, and restart your device.</li>
            </ol>
          </div>`
  },
  {
    categories: ['Device Assistance', 'Account Issues'],
    title: 'I don\'t see the option to use native notifications',
    popular: false,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>Native notifications are only compatible with some devices and browsers. Apple devices currently do not support native notifications. Please see the minimum requirements below.</span>
            <ol>
              <li>Desktop Chrome: Version 40+</li>
              <li>Microsoft Edge: Version 17+</li>
              <li>Desktop Firefox: Version 46+</li>
              <li>Desktop Opera: Version 27+</li>
              <li>Android Webview: Version 40+</li>
              <li>Chrome for Android: Version 40+</li>
              <li>Firefox for Android: Version 46+</li>
              <li>Opera for Android: Version 27+</li>
              <li>Samsung Internet: Version 4.0+</li>
            </ol>

            <p>You can also test your device compatibility by using our <a href="/compatibility"><button class='btn btn-sm btn-register secondary'>Device Compatibility Checker</button></a></p>
          </div>
    `
  },
  {
    categories: ['Payment and Billing', 'Account Issues'],
    title: 'Can I cancel my membership at any time?',
    popular: true,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>Yes! Our membership model is simple. We charge a flat monthly rate for unlimited usage, and you can cancel at any time.</span>

            <p>To view your current membership details or cancel your membership, please visit your <a href="https://app.viyo.io/profile"><button class='btn btn-sm btn-register secondary'>Profile</button></a></p>
          </div>
    `
  },
  {
    categories: ['Payment and Billing', 'Account Issues'],
    title: 'I\'m having trouble subscribing',
    popular: false,
    body: `<div class='col-md-12 faq-item'>
            <span class='faq-header'>Please ensure all of your credit card details are correct. If you are still having issues, please <a href="contact"><button class='btn btn-sm btn-register secondary'>Contact Us</button></a></span>
          </div>
    `
  }
]

module.exports = { categories, questions };