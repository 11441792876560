import React, { Component } from 'react';
// import TextLoop from 'react-text-loop'; Paskwa Mutunga June 2020
import ViyoLogo from './viyoLogo';
import Div100vh from 'react-div-100vh';

const images = [
  require('../assets/images/hero1.jpg'),
  require('../assets/images/hero2.jpg'),
  require('../assets/images/hero3.jpg'),
];

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signupLink: 'https://app.viyo.io/register',
      backgroundImageIndex: 0,
    };
  }

  componentDidMount = () => {
    setInterval(this.cycleImages, 3000);
  };

  componentWillUnmount = () => {
    clearInterval(this.cycleImages);
  };

  renderBackgroundImages = () => {
    const { backgroundImageIndex } = this.state;
    return images.map((img) => {
      const index = images.indexOf(img);

      return (
        <img
          key={index}
          style={{ backgroundImage: `url(${img})` }}
          alt=""
          className={`${
            index === backgroundImageIndex ? 'active' : 'hidden'
          } heroImage`}
        />
      );
    });
  };

  cycleImages = () => {
    const { backgroundImageIndex } = this.state;
    let newIndex =
      backgroundImageIndex < images.length - 1 ? backgroundImageIndex + 1 : 0;
    this.setState({ backgroundImageIndex: newIndex });
  };

  render() {
    return (
      <Div100vh className="auto100vh">
        <section className="slide-bg homeHero" id="home">
          <div className="backgroundOverlay"></div>
          {this.renderBackgroundImages()}
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xs-12 text-xs-center text-lg-left">
                <div className="d-flex-1">
                  <div className="slide-text">
                    <div>
                      <span className="main-title">
                        <ViyoLogo size="large" />
                      </span>
                      <br />
                      {/* <h1>
                        The easiest way to turn a camera-enabled phone, tablet
                        or laptop into a secure home monitoring system.
                      </h1> */}
                      <h1>Work from home with Viyo.</h1>

                      <h5 className="homeHero slide-text heroSubtitle">
                        The easiest way to turn a camera-enabled phone, tablet
                        or laptop into a secure monitoring system for your
                        family.
                      </h5>

                      {/* <h4>Use any device with a web browser and a camera for video monitoring. No hardware purchase or download required!</h4> */}
                      <button
                        className="btn-theme-primary hero"
                        onClick={() =>
                          (window.location.href = this.state.signupLink)
                        }
                      >
                        Get Started Today
                      </button>
                      <br />
                      <a
                        className="cta2 scroll-link text-white"
                        href="#compatibility"
                      >
                        Test your device for compatibility
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="nav-link scrollArrow" href="#about">
            <i className="fa fa-arrow-down"></i>
          </a>
        </section>
      </Div100vh>
    );
  }
}

export default Hero;
