import React from 'react';
import ViyoLogo from './viyoLogo';
import Footer from './footer';


class Contact extends React.Component {
  openChat = () => {
    const element = document.getElementById('Smallchat');
    if (!element) return;
    const iframe = element.children[0];
    if (!iframe) return;
    const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    if (!innerDoc) return;
    const button = innerDoc.getElementsByClassName('Launcher')[0];

    if (button) button.click();
  }
  render() {
    console.log(window.location);
  	return (
      <div>
        <nav className="navbar navbar-expand-lg  theme-nav fixed-top darkHeader">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}><ViyoLogo/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Pricing</a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
                </li> */}

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/register" className='btn btn-sm btn-register'>Register</a></div>
                </li>

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/login" className='btn btn-sm btn-register '>Login</a></div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <section className="contact" id="contact">
          {/* <div className="animation-circle">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div> */}
          <div className="container">
            <div className="row">
                {/* <div className="col-md-6">
                  <div className="section-title">
                      <h2>Live Chat</h2>
                  </div>
                  <div className="auth-form">
                      <div className="form-group mb-0">
                        <button className="btn-theme price-plan-btn" onClick={this.openChat}>Chat Now</button>
                      </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="section-title">
                      <h2>Contact Info</h2>
                  </div>
                  <p>Need some assistance? Reach out to us through email and we'll get back to you as soon as we can.</p>
                  <ul className="contact-box">
                      <li>
                        <div className="contact-circle">
                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div className="contact-text">
                            <h3>Location</h3>
                            <p>Toronto, Canada</p>
                        </div>
                      </li>
                      <li>
                        <div className="contact-circle">
                            <i className="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div className="contact-text">
                            <h3>Email Us</h3>
                            <p><a href="mailto:contact@viyo.io">contact@viyo.io</a></p>
                        </div>
                      </li>
                  </ul>
                </div>
            </div>
          </div>
        </section>
        <Footer history={this.props.history} />
      </div>
  	);
  }
}


export default Contact;