// Paskwa Mutunga June content-changes
// https://react-slick.neostack.com/docs/example/responsive

import React from 'react';

// import Carousel from 'react-bootstrap/Carousel';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// custom arrows

//function Arrow(props) {
// let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
// className += ' testarrow';
// const char = props.type === 'next' ? '⪢' : '⪡';
// const char = props.type === 'next' ? '►' : '◄';

//   return (
//     <span className={className} onClick={props.onClick}>
//       {char}
//     </span>
//   );
// }

// custom arrows ends

class Updtestimonial extends React.Component {
  render() {
    // Dynamic Testimonial Easy to Update
    let data = [
      {
        name: 'Sarah, Access & Privacy Officer',
        photo: '1.png',
        description:
          '"I\'m so happy that there is a privacy and security focused solution that I can use to monitor my child while I work from home"',
      },
      {
        name: 'Brandon, Full-stack Developer',
        photo: '1.png',
        description:
          '"Using Viyo gives us an extra pair of eyes on our 2 kids and dog while we are both working from home. It gives us the confidence that everything is okay at times when we both need to be on our computers"',
      },
    ];

    // Dynamic Testimonial Cards
    let DataCards = data.map((val, i) => {
      return (
        <div className="container" key={i}>
          <div className="row ">
            <div className="card border-light mb-3" id="updTestBox">
              {/* <div className="card-header">Header</div> */}
              <div className="card-body testQuote">
                <p className="card-text text-center ">{val.description}</p>

                {/* <h3 className="mt-0 sub-title text-center">{val.name}</h3> */}
              </div>
              <footer className="testfooter-text text-center">
                {val.name}
              </footer>
            </div>
          </div>
        </div>
      );
    });

    // Slick Slider Logic
    var settings = {
      // nextArrow: <Arrow type="next" />,
      // prevArrow: <Arrow type="prev" />,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    // Slider logic ends

    return (
      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center" id="testimonial-h2">
                Testimonials
              </h2>
              <div className="testimonil-box" id="updTestBox">
                <Slider {...settings}>{DataCards}</Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Updtestimonial;
