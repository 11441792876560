import React, { Component } from 'react';
import ReactSVG from 'react-svg';

import Logo from './../../src/assets/images/viyo-logo.svg';

const LogoWhite = Logo;

class ViyoLogo extends Component {
  render() {
    const { size, dark } = this.props;
    return (
      <div className={`uview-logo ${size}`}>
        <ReactSVG className='uview-logo' src={ dark ? LogoWhite : LogoWhite } />
      </div>
    );
  }
}

export default ViyoLogo;