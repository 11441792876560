import React from 'react';
import ViyoLogo from './viyoLogo';
import Footer from './footer';

const policyContent = (
  <div className='container policy-content'>

    <h1>PRIVACY POLICY</h1>
    <span className='last-updated'>Last updated December 02, 2019</span>

    <p className='intro'>
      Thank you for choosing to be part of our community at Stack Five Inc, doing business as Viyo (“https://viyo.io”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at contact@viyo.io. 
    </p>

    <p className='intro'>
      When you visit our website https://viyo.io, mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy   that you do not agree with, please discontinue use of our Sites or Apps and our services. 
    </p>

    <p className='intro'>
      This privacy policy applies to all information collected through our website (such as https://viyo.io), mobile application,  ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services").  
    </p>

    <p className='intro'>
      Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.   
    </p>

    { tableOfContents() }
    { sections() }
  </div>
);

function tableOfContents() { 
  return <div className='toc'>
    <h3>TABLE OF CONTENTS</h3>
    <ol>
      <li><a href='#section1'>WHAT INFORMATION DO WE COLLECT?</a></li>
      <li><a href='#section2'>HOW DO WE USE YOUR INFORMATION?</a></li>
      <li><a href='#section3'>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
      <li><a href='#section4'>WHO WILL YOUR INFORMATION BE SHARED WITH?</a></li>
      <li><a href='#section5'>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
      <li><a href='#section6'>HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
      <li><a href='#section7'>HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
      <li><a href='#section8'>DO WE COLLECT INFORMATION FROM MINORS?</a></li>
      <li><a href='#section9'>WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
      <li><a href='#section10'>DATA BREACH</a></li>
      <li><a href='#section11'>CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
      <li><a href='#section12'>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
      <li><a href='#section13'>DO WE MAKE UPDATES TO THIS POLICY?</a></li>
      <li><a href='#section14'>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a></li>
    </ol>
  </div>
}

function sections() {
  return <div className='sections'>
    { section1() }
    { section2() }
    { section3() }
    { section4() }
    { section5() }
    { section6() }
    { section7() }
    { section8() }
    { section9() }
    { section10() }
    { section11() }
    { section12() }
    { section13() }
    { section14() }
  </div>
}

function section1() {
  return <div className='section section1' id='section1'>
    <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
    <span className='subtitle'>Personal information you disclose to us</span>
    <span className='in-short'>
      In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.
    </span>
    <p>
      We collect personal information that you voluntarily provide to us when registering at the Services or Apps, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services or Apps or otherwise contacting us.
    </p>
    <p>
      The personal information that we collect depends on the context of your interactions with us and the Services or Apps, the choices you make and the products and features you use. The personal information we collect can include the following:
    </p>
    <p>
      Publicly Available Personal Information. We collect first name, maiden name, last name, nickname, email addresses, current and former address, and other similar data.   
    </p>
    <p>
      Personal Information Provided by You. We collect financial information (credit card number, purchase history, invoices), purchase history, app usage, data collected from surveys, and other similar data.   
    </p>
    <p>
      Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.
    </p>
    <p>
      Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy policy link(s) here: https://stripe.com/en-ca.  
    </p>
    <p>
      All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.   
    </p>

    <span className='subtitle'>Information automatically collected</span>
    <span className='in-short'>In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Services or Apps.</span>
    <p>
      We automatically collect certain information when you visit, use or navigate the Services or Apps. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services or Apps and other technical information. This information is primarily needed to maintain the security and operation of our Services or Apps, and for our internal analytics and reporting purposes.
    </p>
    <p>
      Like many businesses, we also collect information through cookies and similar technologies.  
    </p>
    <p>
      Online Identifiers. We collect cookie identifiers, or others such as the ones used for analytics and marketing, applications, devices, tools and protocols, such as IP (Internet Protocol) addresses, and other similar data.  
    </p>

    <span className='subtitle'>Information collected through our Apps </span>
    <span className='in-short'>In Short:  We may collect information regarding your mobile device, push notifications, when you use our apps.</span>
    <p>
      If you use our Apps, we may also collect the following information:    
    </p>
    <p>
      Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device’s camera, microphone, storage, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.     
    </p>
    <p>
      Mobile Device Data. We may automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.    
    </p>
    <p>
      Push Notifications. We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.     
    </p>
  </div>
}

function section2() {
  return <div className='section section2' id='section2'>
    <h3>2. HOW DO WE USE YOUR INFORMATION?</h3>
    <span className='in-short'>
      In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.  
    </span>

    <p>
      We use personal information collected via our Services or Apps for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below. 
    </p>

    <p>We use the information we collect or receive: </p>

    <ul>
      <li>
        To facilitate account creation and logon process. If you choose to link your account with us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.      
      </li>
      <li>
        To send you marketing and promotional communications. We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the " WHAT ARE YOUR PRIVACY RIGHTS  " below).      
      </li>
      <li>
        To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.       

      </li>
      <li>
        Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services or Apps.            
      </li>
      <li>
        Deliver targeted advertising to you. We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.                 
      </li>
      <li>
        Request Feedback. We may use your information to request feedback and to contact you about your use of our Services or Apps.                  
      </li>
      <li>
        To protect our Services. We may use your information as part of our efforts to keep our Services or Apps safe and secure (for example, for fraud monitoring and prevention).  
      </li>
      <li>
        To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.  
      </li>
      <li>
        To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.   
      </li>
      <li>
        To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.                                                    
        </li>
      <li>
        To deliver services to the user. We may use your information to provide you with the requested service.                                                                                
      </li>
      <li>
        To respond to user inquiries/offer support to users.  We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.                                                 
      </li>
      <li>
        For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services or Apps, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.            
      </li>
    </ul>
  </div>
}

function section3() {
  return <div className='section section3' id='section3'>
    <h3>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>

    <span className='in-short'>
      In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
    </span>

    <p>We may process or share data based on the following legal basis:</p>

    <ul>
      <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose. </li>
      <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. </li>
      <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. </li>
      <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). </li>
      <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
    </ul>

    <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>

    <ul>
      <li>Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services or Apps, which will enable them to collect data about how you interact with the Services or Apps over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.  We have contracts in place with our data processors. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will not share your personal information with any organisation apart from us. They will hold it securely and retain it for the period we instruct.</li>
      <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>       
      <li>Offer Wall. Our Apps may display a third-party hosted “offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer. Such an offer wall may appear in our mobile application and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will leave our mobile application. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.</li>
    </ul>
  </div>
}

function section4() {
  return <div className='section section4' id='section4'>
    <h3>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h3>
    <span className='in-short'>In Short:  We only share information with the following third parties.</span>
    <p>We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.</p>
    <ul>
      <li>Communicate and Chat with Users: SmallChat</li>
      <li>Invoice and Billing: Stripe</li>
      <li>Web and Mobile Analytics: Google Analytics</li>  
      <li>Website Hosting: Heroku and Netlify</li>
    </ul>
  </div>
}

function section5() {
  return <div className='section section5' id='section5'>
    <h3>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
    <span className='in-short'>In Short:  We may use cookies and other tracking technologies to collect and store your information.</span>
    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <a href="#cookie-policy">Cookie Policy</a>.</p>
  </div>
}

function section6() {
  return <div className='section section6' id='section6'>
    <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
    <span className='in-short'>In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</span>
    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us .</p>
    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
  </div>
}

function section7() {
  return <div className='section section7' id='section7'>
    <h3>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
    <span className='in-short'>In Short:  We aim to protect your personal information through a system of organizational and technical security measures.</span>
    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services or Apps is at your own risk. You should only access the services within a secure environment.</p>
  </div>
}

function section8() {
  return <div className='section section8' id='section8'>
    <h3>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
    <span className='in-short'>In Short:  We do not knowingly collect data from or market to children under 18 years of age.</span>
    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services or Apps, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services or Apps. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at contact@viyo.io.</p>
  </div>
}

function section9() {
  return <div className='section section9' id='section9'>
    <h3>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
    <span className='in-short'>In Short:  In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span>
    <p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details   provided below. We will consider and act upon any request in accordance with applicable data protection laws. </p>
    <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>
    <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>here</a>.</p>
    <p>If you have questions or comments about your privacy rights, you may email us at contact@viyo.io.</p>

    <span className='subtitle'>Account Information</span>
    <p>If you would at any time like to review or change the information in your account or terminate your account, you can: Log into your account settings and update your user account.</p>
    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
    <p>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services or Apps. To opt-out of interest-based advertising by advertisers on our Services or Apps visit http://www.aboutads.info/choices/  .</p>
    <p>Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may: 1) Note your preferences when you register an account with the site. 2) Access your account settings and update preferences. </p>
  </div>
}

function section10() {
  return <div className='section section10' id='section10'>
    <h3>10. DATA BREACH</h3>
    <p>A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when Stack Five Inc believes you are likely to be at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that Stack Five Inc becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information Stack Five Inc will promptly investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.</p>
  </div>
}

function section11() {
  return <div className='section section11' id='section11'>
    <h3>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy   .  </p>
  </div>
}

function section12() {
  return <div className='section section12' id='section12'>
    <h3>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
    <span className='in-short'>In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span>
    <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. </p>
    <p>If you are under 18 years of age, reside in California, and have a registered account with the Services or Apps, you have the right to request removal of unwanted data that you publicly post on the Services or Apps. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services or Apps, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
  </div>
}

function section13() {
  return <div className='section section13' id='section13'>
    <h3>13. DO WE MAKE UPDATES TO THIS POLICY?</h3>
    <span className='in-short'>In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.</span>
    <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.  </p>
  </div>
}

function section14() {
  return <div className='section section14' id='section14'>
    <h3>14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
    <p>If you have questions or comments about this policy, you may email us at contact@viyo.io</p>
    <h3>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
    <p>Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request by contacting contact@viyo.io. We will respond to your request within 30 days.</p>
  </div>
}

function cookiePolicy() {
  return <div className='cookie-policy container' id="cookie-policy">
    <h2>Cookie Policy for Viyo</h2>
    <p>This is the Cookie Policy for Viyo, accessible from https://viyo.io</p>
    <p><strong>What Are Cookies</strong></p>
    <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>
    <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>
    <p><strong>How We Use Cookies</strong></p>
    <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
    <p><strong>Disabling Cookies</strong></p>
    <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
    <p><strong>The Cookies We Set</strong></p>
    <ul>
      <li>
        <p>Login related cookies</p>
        <p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
      </li>
      <li>
        <p>Site preferences cookies</p>
        <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
      </li>
    </ul>
    <p><strong>Third Party Cookies</strong></p>
    <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
    <ul>
      <li>
        <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
        <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
      </li>
    </ul>
    <p><strong>More Information</strong></p>
    <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the <a href="https://www.cookiepolicygenerator.com">Cookies Policy Template Generator</a> and the <a href="https://www.webterms.org">WebTerms Generator</a>.</p>
    <p>However if you are still looking for more information then you can contact us through email at contact@viyo.io</p>
  </div>
}

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    console.log('props', window.UVScrollToHash)
    if (this.props.location.hash) {
      if (window.UVScrollToHash) {
        window.UVScrollToHash(this.props.location.hash);
      }
    }
  }
  render() {
    return <>
      <div className='PrivacyPolicy' id="top">
        <nav className="navbar navbar-expand-lg  theme-nav fixed-top darkHeader">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}><ViyoLogo/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" >Pricing</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
                </li> */}

                <li className='nav-item appButton'>
                  <div className='nav-button'>
                    <a href="https://app.viyo.io/register" className='btn btn-sm btn-register'>Register</a></div>
                </li>

                  <li className='nav-item appButton'>
                    <div className='nav-button'>
                      <a href="https://app.viyo.io/login" className='btn btn-sm btn-register '>Login</a></div>
                  </li>
              </ul>
            </div>
          </div>
        </nav>
        
        { policyContent }
        { cookiePolicy() }
        <Footer history={this.props.history} />
      </div>
    </>
  }
}

export default PrivacyPolicy;