import './index.scss';
import React from 'react';
import Cookies from 'universal-cookie';
// import About from './components/about';
import Feature from './components/feature';
import Price from './components/price';
import Footer from './components/footer';
import ViyoLogo from './components/viyoLogo';
import Hero from './components/hero';
import CompatibilityChecker from './components/compatibilityChecker';
import GetStarted from './components/getStarted';
import Updtestimonial from './components/updtestimonial';
import UseCases from './components/useCases';

const cookies = new Cookies();

class HomeGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      signupLink: 'https://app.viyo.io/register',
      signinLink: 'https://app.viyo.io',
      manageScreen: 'https://app.viyo.io/manage',
      showCookiePopup: true,
    };
  }
  componentDidMount() {
    // Fetch authed cookie from app to show better navbar buttons
    const isAuthed = document.cookie.indexOf('UVIsAuthed=true') > -1;
    cookies.set('selectedTheme', 'GeneralTheme');
    if (cookies.get('acceptedCookieTerms')) {
      this.setState({ showCookiePopup: false });
    }

    if (this.props.location.hash) {
      if (window.UVScrollToHash) {
        window.UVScrollToHash(this.props.location.hash);
      }
    }
    this.setState({ isAuthed });
  }
  closeCookiePopup = () => {
    this.setState({ showCookiePopup: false });
    cookies.set('acceptedCookieTerms', 'true');
  };
  render() {
    document.body.classList.remove('inner-page');
    document.body.classList.add('GeneralTheme');

    return (
      <div>
        {/* Navbar Section*/}
        <nav className="navbar navbar-expand-lg theme-nav fixed-top nav-home">
          <div className="container">
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
              <ViyoLogo />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mainmenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa fa-align-justify" aria-hidden="true"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="mainmenu">
              <ul className="navbar-nav ml-auto" id="mymenu">
                <li className="nav-item ">
                  <a className="nav-link scroll-link" href="#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll-link" href="#feature">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll-link" href="#price">
                    Pricing
                  </a>
                </li>
                {/* <li className="nav-item">
								<a className="nav-link" href="https://shop.viyo.io" >Accessories</a>
							</li> */}
                {/* <li className="nav-item">
								<a className="nav-link" href="#compatibility">Compatibility Test</a>
							</li>
              <li className="nav-item">
								<a className="nav-link" href="#security" >Security</a>
							</li> */}

                {!this.state.isAuthed && (
                  <li className="nav-item appButton">
                    <div className="nav-button">
                      <a
                        href={this.state.signupLink}
                        className="btn btn-sm btn-register"
                      >
                        Register
                      </a>
                    </div>
                  </li>
                )}

                {!this.state.isAuthed && (
                  <li className="nav-item appButton">
                    <div className="nav-button">
                      <a
                        href={this.state.signinLink}
                        className="btn btn-sm btn-register "
                      >
                        Login
                      </a>
                    </div>
                  </li>
                )}

                {this.state.isAuthed && (
                  <li className="nav-item appButton">
                    <div className="nav-button">
                      <a
                        href={this.state.manageScreen}
                        className="btn btn-sm btn-register"
                      >
                        View My Cameras
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>

        {/* Home One Section Start */}
        <Hero />
        {/* Home One Section End */}

        {/*Getting Started Component*/}
        <GetStarted signupLink={this.state.signupLink} />

        {/* About Component
        <About signupLink={this.state.signupLink} />*/}

        {/* useCases Component*/}
        <UseCases signupLink={this.state.signupLink} />

        {/* Feature Component*/}
        <Feature signupLink={this.state.signupLink} />

        {/*UpdTestimonial Component*/}
        <Updtestimonial signupLink={this.state.signupLink} />

        <CompatibilityChecker signupLink={this.state.signupLink} />

        {/*Price Component*/}
        <Price signupLink={this.state.signupLink} />

        {/* Security Component */}
        {/* <Security /> */}

        {/* <EWaste /> */}

        <section className="download-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 display-flex">
                <div className="footer-logo">
                  <ViyoLogo />
                </div>
              </div>
              <div className="col-xl-5 display-flex">
                <div className="download-text">
                  <h3>
                    Sign up with just your email
                    <br />
                    and get started for free!
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 display-flex">
                <div className="download-img">
                  <ul>
                    <button
                      className="price-plan-btn"
                      onClick={() =>
                        (window.location.href = this.state.signupLink)
                      }
                    >
                      Sign Up
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Footer Component*/}
        <Footer history={this.props.history} />

        {/*tap-top*/}
        <div className="tap-top">
          <div>
            <i className="fa fa-angle-up" aria-hidden="true"></i>
          </div>
        </div>

        {this.state.showCookiePopup && (
          <div className="cookie-popup">
            <p>
              This site uses cookies. By continuing to use this website, you
              agree to their use. To see how we use cookies{' '}
              <a href="/privacy#section5">click here</a>. To find out more,
              including how to control cookies, see our{' '}
              <a href="/privacy#cookie-policy">Cookie Policy</a>
            </p>
            <button onClick={this.closeCookiePopup}>Close and Accept</button>
          </div>
        )}
      </div>
    );
  }
}

export default HomeGeneral;
