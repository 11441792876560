import React, { Component } from 'react';
import { Line } from 'rc-progress';
import Modal from 'react-modal';

import ErrorMessages from '../utils/errorMessages.js';

import { MdCheckCircle, MdErrorOutline } from 'react-icons/md';

import _ from 'lodash';

import CompatibilityUtilities from '../utils/compatibilityCheckerUtils.js';

Modal.setAppElement('#root');

const ModalStyles = {
  overlay: {
    zIndex: 1010,
    backgroundColor: 'rgba(0,0,0,0.75)',
    cursor: 'pointer',
  },
  content: {
    zIndex: 2,
    cursor: 'auto',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    overflow: 'visible',
    position: 'absolute',
    backgroundColor: 'white',
  },
};

const randomWholeNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

class CompatibilityChecker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
      reportComplete: false,
      progressPercent: 0,
      progressMessage: 'Initializing test suite...',
      showPermissionModal: false,
      showDeniedAccessMessage: false,
      reportBreakdown: {},
    };
  }

  closeModal = (cancel) => {
    this.setState({ showPermissionModal: false });
    if (cancel) {
      this.setState({ inProgress: false });
    } else {
      this.testDevice();
    }
  };

  testDevice = async () => {
    this.setState({ inProgress: true });
    let stream;

    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    } catch (e) {
      this.setState({ showDeniedAccessMessage: true, inProgress: false });
      return;
    }

    await CompatibilityUtilities.initializeDetection(randomWholeNumber(1, 3));
    this.setState({ progressPercent: randomWholeNumber(1, 10) });

    const cameraPermissions = await CompatibilityUtilities.checkHasVideoPermissions(
      randomWholeNumber(1, 3)
    );
    this.setState({
      progressPercent: randomWholeNumber(11, 20),
      progressMessage: 'Checking camera permissions...',
    });

    const audioPermissions = await CompatibilityUtilities.checkHasMicPermissions(
      randomWholeNumber(1, 3)
    );
    this.setState({
      progressPercent: randomWholeNumber(21, 30),
      progressMessage: 'Checking audio permissions...',
    });

    const isWebSocketsSupported = await CompatibilityUtilities.checkHasWebSocketsSupport(
      0
    );
    this.setState({
      progressPercent: randomWholeNumber(31, 50),
      progressMessage: 'Checking web socket compatibility...',
    });

    const hasVideoDevices = await CompatibilityUtilities.checkHasVideoDevices(
      randomWholeNumber(1, 3)
    );
    this.setState({
      progressPercent: randomWholeNumber(51, 65),
      progressMessage: 'Looking for available camera devices...',
    });

    const hasAudioDevices = await CompatibilityUtilities.checkHasAudioDevices(
      randomWholeNumber(1, 3)
    );
    this.setState({
      progressPercent: randomWholeNumber(66, 88),
      progressMessage: 'Looking for available microphones...',
    });

    const isWebRTCSupported = await CompatibilityUtilities.isWebRTCSupported(
      randomWholeNumber(1, 3)
    );
    this.setState({
      progressPercent: 100,
      progressMessage: 'Success! Generating report...',
      reportBreakdown: {
        cameraPermissions,
        audioPermissions,
        isWebSocketsSupported,
        hasVideoDevices: hasVideoDevices.length > 0 ? true : false,
        hasAudioDevices: hasAudioDevices.length > 0 ? true : false,
        isWebRTCSupported,
      },
    });
    setTimeout(() => {
      this.setState({ reportComplete: true, inProgress: false });
      stream.getTracks().forEach((t) => t.stop());
    }, 1500);
  };

  renderProgressBar = () => {
    const { progressPercent, progressMessage } = this.state;
    return (
      <div className="progressBar">
        <span>{progressPercent}%</span>
        <Line percent={progressPercent} />
        <p>{progressMessage}</p>
      </div>
    );
  };

  renderProgressDetails = () => {
    return (
      <div className="details">
        <p>
          Please wait while we check your device's status.{' '}
          <strong>
            If you see a prompt to allow access to camera and audio, please
            click "allow".
          </strong>
        </p>
      </div>
    );
  };

  renderWelcome = () => {
    return (
      <div>
        <button
          className="btn-theme-primary hero"
          style={{
            fontSize: '24px',
            padding: '20px 40px',
            marginTop: '0',
          }}
          onClick={() => {
            this.setState({ showPermissionModal: true });
          }}
        >
          Test My Device
        </button>
      </div>
    );
  };

  renderIssues = (issues) => {
    return _.map(issues, (issue, idx) => {
      return (
        <p key={idx} className="issue">
          {idx + 1}) {ErrorMessages[issue].message}
        </p>
      );
    });
  };

  renderReportDetails = () => {
    const { reportBreakdown } = this.state;
    const issuesArr = Object.keys(reportBreakdown).filter(
      (key) => reportBreakdown[key] === false
    );
    const hasDealBreaker = Object.keys(reportBreakdown).some((key) => {
      const typeIsNotCompatible = reportBreakdown[key] === false;
      const typeIsDealBreaker = ErrorMessages[key].dealBreaker === true;
      return typeIsNotCompatible && typeIsDealBreaker;
    });

    return (
      <div className="reportDetails">
        {(issuesArr.length === 0 || !hasDealBreaker) && (
          <div className="successReport">
            <MdCheckCircle />
            <h5>Congratulations!</h5>
            <p>You're all set. Your device will work with Viyo.</p>
            {issuesArr.length > 0 && (
              <div>
                <p>
                  There are some potential areas for improvement, though. Please
                  see the feedback below.
                </p>
                {this.renderIssues(issuesArr)}
              </div>
            )}
            <a href="https://app.viyo.io">
              <button className="updprice-plan-btn">Let's Get Started</button>
            </a>
          </div>
        )}

        {issuesArr.length > 0 && hasDealBreaker && (
          <div className="errorReport">
            <MdErrorOutline />
            <h5>Not Compatible</h5>
            <p>
              Unfortunately your device isn't currently compatible with Viyo.
              There may be some steps you can take to fix this. Please see the
              feedback below.
            </p>
            {this.renderIssues(issuesArr)}
            <a href="/faq">
              <button className="updprice-plan-btn">
                Frequently Asked Questions
              </button>
            </a>
          </div>
        )}
      </div>
    );
  };

  renderDeniedAccessMessage = () => {
    return (
      <div className="accessDenied">
        <h3>
          Unfortunately, you have either previously denied Viyo access to your
          camera and audio settings or there was an issue accessing your
          devices. You will not be able to use Viyo or this compatibility test
          until you resolve this issue. Modern web browsers require that you
          manually enable these settings.
        </h3>
        <h3>
          To enable these settings, please{' '}
          <a href="/faq#enablePermission">click here</a> to visit our
          troubleshooting guide.
        </h3>
      </div>
    );
  };

  render() {
    const { inProgress, reportComplete, showDeniedAccessMessage } = this.state;

    return (
      <section className="CompatibilityChecker" id="compatibility">
        <div className="container">
          <div className="col-12 text-center">
            <div className="section-title">
              <h2>Device Compatibility Test</h2>
              {!inProgress && !reportComplete && (
                <p>
                  Viyo is built using cutting edge web technologies. While we
                  try to support as many devices as possible, please begin the
                  automated test below to see if your device is compatible with
                  Viyo.
                </p>
              )}
            </div>

            {!inProgress &&
              !reportComplete &&
              !showDeniedAccessMessage &&
              this.renderWelcome()}
            {reportComplete && this.renderReportDetails()}

            {inProgress && this.renderProgressBar()}
            {inProgress && this.renderProgressDetails()}

            {!inProgress &&
              showDeniedAccessMessage &&
              this.renderDeniedAccessMessage()}
          </div>
        </div>

        <Modal
          isOpen={this.state.showPermissionModal}
          onRequestClose={this.closeModal}
          contentLabel={'Permission Requested'}
          style={ModalStyles}
          closeTimeoutMS={300}
        >
          <h3>Permission Request Possibility</h3>
          <p>
            If you see a prompt to allow access to camera and audio, please
            click the "allow" button. This will enable us to check your device
            for compatibility, as well set up your device for future use with
            this application.
          </p>
          <div className="actionButtons">
            <button
              className="price-plan-btn"
              onClick={() => this.closeModal(false)}
            >
              Continue
            </button>
          </div>
        </Modal>
      </section>
    );
  }
}

export default CompatibilityChecker;
