import React from 'react';
import FeaturesWaveBG from './../assets/images/features-wave-bg.svg';
import Accordion from '../components/accordion';

const data = [
  {
    icon1: 'fa fa-shield',
    heading: 'Secure',
    content:
      "We don't store your video - period. While you can't rewind to see that cute thing Ellie did, no one can hack your stored video, either",
  },
  {
    icon1: 'fa fa-bell',
    heading: 'Industry-Leading',
    content:
      'Video and audio stream encryption,  industry-standard WebRTC connections through  STUN/TURN.',
  },
  {
    icon1: 'fa fa-mobile',
    icon2: ' fa fa-globe',
    icon3: ' fa fa-tablet',
    icon4: ' fa fa-desktop',
    heading: 'Live Multi-Camera View',
    content: 'Keep an eye on all of your cameras - and kids - at one time',
  },
  {
    icon1: ' fa fa-globe',
    heading: 'Unlimited Cameras',
    content:
      'Kids playing in different rooms of the house? Upgrade to premium and set up one camera in each room - no new hardware needed.',
  },
  {
    icon1: 'fa fa-exclamation',
    heading: 'Two-Way Voice',
    content:
      'Because sometimes, you need to be able to tell Joe to stop teasing his sister in the middle of a conference call.',
  },
  {
    icon1: 'fa fa-th',
    heading: 'No Installation Required',
    content:
      'No apps needed - with Viyo, simply login on a browser to get started.',
  },
];

class Feature extends React.Component {
  // Mobile layout
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  // Mobile layout
  render() {
    // If mobile
    const { width } = this.state;
    const isMobile = width <= 500;
    // the rest is the same...

    if (isMobile) {
      return (
        <section className="feature theme-bg" id="feature">
          <div className="wave-bg-container top">
            <img
              src={FeaturesWaveBG}
              alt="Features wave background"
              className="features-wave-bg"
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12 text-left">
                <div className="section-title">
                  <h2 className="text-white">Features</h2>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 featureGroup">
                <div className="future-box">
                  <div className="future-timeline">
                    {/* <div className="mobileApp">
            <Accordion data={data} />
          </div> */}
                    <ul>
                      <li className="timeline">
                        <div className="">
                          <Accordion data={data} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 future-mobile">
                <img
                  src="assets/images/feature-mob2.png"
                  alt="feature-mob"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-4 col-sm-12 featureGroup">
                <div className="future-box"></div>
              </div>
            </div>
          </div>

          <div className="wave-bg-container bottom">
            <img
              src={FeaturesWaveBG}
              alt="Features wave background"
              className="features-wave-bg"
            />
          </div>
        </section>
      );
    } else {
      return (
        <section className="feature theme-bg" id="feature">
          <div className="wave-bg-container top">
            <img
              src={FeaturesWaveBG}
              alt="Features wave background"
              className="features-wave-bg"
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-title">
                  <h2 className="text-white">Features</h2>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 featureGroup">
                <div className="future-box">
                  <div className="future-timeline">
                    <ul>
                      <li className="timeline">
                        <i className="fa fa-shield" aria-hidden="true"></i>
                        <h4 className="sub-title">Secure</h4>
                        <p className="">
                          We don't store your video - period. While you can't
                          rewind to see that cute thing Ellie did, no one can
                          hack your stored video, either.
                        </p>
                      </li>
                      <li className="timeline">
                        <i className="fa fa-bell" aria-hidden="true"></i>
                        <h4>Industry-Leading</h4>
                        <p className="">
                          Video and audio stream encryption, industry-standard
                          WebRTC connections through STUN/TURN.
                        </p>
                      </li>
                      {/* <li className="timeline">
                                <h4 className="sub-title">Feedback-Driven Updates</h4>
                                <p className="">We value your opinion, and we're constantly improving our platform to bring you the features you value most at no extra cost.</p>
                             </li> */}
                      <li className="timeline">
                        <div className="multiPhone">
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                          <i className="fa fa-tablet" aria-hidden="true"></i>
                          <i className="fa fa-desktop" aria-hidden="true"></i>
                        </div>
                        <h4 className="sub-title">Live Multi-Camera View</h4>
                        <p className="">
                          Keep an eye on all of your cameras - and kids - at one
                          time.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 future-mobile">
                <img
                  src="assets/images/feature-mob2.png"
                  alt="feature-mob"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-4 col-sm-12 featureGroup">
                <div className="future-box">
                  <div className="future-timeline-right">
                    <ul className="text-left">
                      <li className="timeline-right">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        <h4 className="sub-title">Unlimited Cameras</h4>
                        <p className="">
                          Kids playing in different rooms of the house? Upgrade
                          to premium and set up one camera in each room - no new
                          hardware needed.
                        </p>
                      </li>
                      <li className="timeline-right">
                        <i className="fa fa-exclamation" aria-hidden="true"></i>
                        <h4>Two-Way Voice</h4>
                        <p className="">
                          Because sometimes, you need to be able to tell Joe to
                          stop teasing his sister in the middle of a conference
                          call.
                        </p>
                      </li>
                      <li className="timeline-right">
                        <i className="fa fa-th" aria-hidden="true"></i>
                        <h4>No Installation Required</h4>
                        <p className="">
                          No apps needed - with Viyo, simply login on a browser
                          to get started.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wave-bg-container bottom">
            <img
              src={FeaturesWaveBG}
              alt="Features wave background"
              className="features-wave-bg"
            />
          </div>
        </section>
      );
    }
  }
}
export default Feature;
