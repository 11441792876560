import React from 'react';
import OwlCarousel from 'react-owl-carousel';


class Price extends React.Component {
  render() {
    // OwlCarousel Option for Prices
    const options = {
        0:{
            items:1,
            nav:false,
            dots:true
        },
        600:{
            items:2,
            nav:false,
            dots:true
        },
        767:{
            items:2,
            nav:false,
            dots:true
        },
        992:{
            items:2,
            nav:false,
            dots:true
        },
        1000:{
            items:2
        }
    };

    // Dynamic Price Data Easy to Update
    let data = [
        {
          title: 'Free Tier',
          lable: 'Unlimited Usage',
          price: '0',
          features: `<li>1 Camera</li>
                    <li>24/7 Live Streaming</li>
                    <li>Earn additional free cameras with referrals</li>
                    <li>Free Forever</li>`,
          link: this.props.signupLink
        }, {
          title: 'Premium Tier',
          lable: 'Unlimited Usage',
          price: '5',
          features: `<li>Unlimited Cameras</li>
                    <li>Two-way Audio</li>
                    <li>Slack Motion Tracking Notifications</li>
                    <li>Multi Camera View</li>
                    <li>24/7 Live Streaming</li>`,
          link: this.props.signupLink
        },
    ];

    // Dynamic Price Data Loop
    let DataList = data.map((val, i) => {
        return (
            <div className="item" key={i}>
                <div className="package-box">
                  <h3>{val.title}</h3>
                    <div className="price-box">
                        <span>$</span>
                        <h2>{val.price}</h2>
                        <h5 className="plan-clr">
                            <span className="d-block">{i !== 0 ? 'USD / Month' : ''}</span>
                        </h5>
                    </div>
                    <div className="updprice-plan-btn" onClick={() => window.location.href = val.link}>{i === 0 ? 'Get Started for Free' : 'Subscribe'}</div>
                    <div className="price-plan text-center">
                        <ul>
                            <li>{val.lable}</li>
                            <div dangerouslySetInnerHTML={{__html: val.features}}></div>
                        </ul>
                    </div>
                </div>
            </div>
        );
    });
		
  	return (
        <section id="price">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-title">
                            <h2>Pricing</h2>
                        </div>
                    </div>
                    <div className="col-md-12 text-center">
                    <OwlCarousel
                        className="plan-slider owl-carousel owl-theme"
                        loop={false}
                        items={2}
                        margin={15}
                        navText={['<i class="fa fa-angle-left"></i>' , '<i class="fa fa-angle-right"></i>']}
                        nav={true}
                        dots={false}
                        responsive={options}
                    >
                       {DataList}
                    </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}


export default Price;