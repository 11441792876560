import React from 'react';
import GetStarted1 from './../assets/images/get-started-1.png';
import GetStarted2 from './../assets/images/get-started-2.png';
import GetStarted3 from './../assets/images/get-started-3.png';
import Arrow1to2 from './../assets/images/arrow-1-2.svg';
import Arrow2to3 from './../assets/images/arrow-2-3.svg';


class GetStarted extends React.Component {
  render() {
		
  	return <>
      <section className="get-started" id="get-started">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Here's how:</h2>
            </div>
            <div className="col-xs-12 get-started-section gss1">
              <img src={GetStarted1} alt="Get started 1" />
              <p>Check if the device you want to use as a camera is compatible with Viyo <a className="scroll-link" href="#compatibility">here</a>.</p>
              <img src={Arrow1to2} alt="Get started arrow 1 to 2" className="arrow" />
            </div>
            <div className="col-xs-12 get-started-section gss2">
              <img src={Arrow2to3} alt="Get started arrow 2 to 3" className="arrow" />
              <img src={GetStarted2} alt="Get started 2" />
              <p>Make an account and follow the instructions to configure your setup.</p>
            </div>
            <div className="col-xs-12 get-started-section gss3">
              <img src={GetStarted3} alt="Get started 3" />
              <p>Begin Monitoring!</p>
            </div>
          </div>
        </div>
      </section>
    </>
  }
}

export default GetStarted;