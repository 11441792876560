const ErrorMessages = {
  cameraPermissions: {
    dealBreaker : true,
    message     : 'You have not given Viyo permission to view your device\'s camera. To enable camera access, please see our FAQ page.'
  },
  audioPermissions: {
    dealBreaker : false,
    message     : 'You have not given Viyo permission to use your device\'s microphone. If there are no issues related to your device\'s camera, you can still use your device as a camera but audio will not be transmitted.'
  },
  isWebSocketsSupported: {
    dealBreaker : true,
    message     : 'Your device does not support web sockets. You may run into some issues using our application. This is likely due to the age of your device, or the operating system version your device is using. If possible, please update your device and try again.'
  },
  hasVideoDevices: {
    dealBreaker : true,
    message     : 'Your device does not have any camera devices available. This may be related to hardware issues, or the age of your device or operating system. If possible please try updating your device and try again or reach out to our support team for assistance.'
  },
  hasAudioDevices: {
    dealBreaker : false,
    message     : 'Your device does not have any microphone devices available. This may be related to hardware issues, or the age of your device or operating system. If there are no issues related to your device\'s camera, you can still use your device as a camera but audio will not be transmitted.'
  },
  isWebRTCSupported: {
    dealBreaker : true,
    message     : 'Your device does not support the web technology we use for transmitting video and audio. This may be related to the age of your device, the version of your operating system, or the browser you are using. If possible, please update your device and try again. We also recommend using the Chrome browser which can be downloaded in your device\'s app store. The Chrome browser provides a wide range of support for the technologies we use and may resolve any issues you have.'
  },
}

export default ErrorMessages;